@font-face {
  font-family: 'Proxima Nova Bold';
  src: url('assets/fonts/ProximaNova-Bold.otf');
}

@font-face {
  font-family: 'Proxima Nova Regular';
  src: url('assets/fonts/ProximaNova-Regular.otf');
}

@font-face {
  font-family: 'Batman Bold';
  src: url('assets/fonts/batmfa__.ttf');
}

/* @font-face {
  font-family: 'Proxima Nova Regular';
  src: url('assets/fonts/ProximaNova-Regular.otf');
} */

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/* Fight Page */
.page-title {
  font-family: 'Batman Bold', sans-serif;
  font-size: 70px;
  font-weight: 800;
  color: #ffff;
  padding: 20px 0;
}

.page-subtitle {
  font-family: 'Proxima Nova Bold', sans-serif;
  font-weight: 700;
  font-size: 28px;
  color: #ffff;
}

.page-title-line {
  border: 1px solid #656565;
  width: 100%;
}

.text-red {
  color: #d2222d;
}

.fight-page-card {
  background-image: url('assets/img/fight-card-bg.svg');
  background-position: center;
  background-size: cover;
  border: 2px solid #a3a1a1;
  padding: 35px;
  background-color: rgba(22, 22, 22, 0.33);
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  color: #e4e4e4;
  min-height: 470px !important;
}

.collection-card {
  background: rgba(22, 22, 22, 0.5);
  border: 2px solid #23f7dd;
  border-radius: 20px;
}

.collection-card-image {
  width: 100%;
  max-height: 250px;
  border-radius: 20px 20px 0 0;
}

.collection-card-body {
  padding: 20px;
}

.collection-card-title {
  color: #ffffff;
  font-family: 'Proxima Nova Regular', sans-serif;
}

.login-card {
  background-image: url('assets/img/fight-card-bg.svg');
  background-position: center;
  background-size: cover;
  border: 2px solid #a3a1a1;
  padding: 35px;
  background-color: rgba(22, 22, 22, 0.33);
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  color: #e4e4e4;
}

.fight-page-card-id {
  font-family: 'Proxima Nova Bold', sans-serif;
  font-weight: 400;
  font-size: 28px;
  line-height: 28px;
  text-align: center;
  padding-bottom: 34px;
}

.text-blue {
  color: #23f7dd;
}

.bg-blue {
  background-color: #23f7dd !important;
}

.bg-red {
  background-color: #d2222d !important;
}

.text-darkblue {
  color: rgb(8, 8, 179) !important;
}

.bg-darkblue {
  background-color: rgb(8, 8, 179) !important;
}

.fight-page-card-text-white {
  color: #ffffff;
}

.fight-page-card-body-title {
  font-family: 'Proxima Nova Regular', sans-serif;
  font-weight: 250;
  font-size: 20px;
  color: #a3a1a1;
}

.fight-page-card-lines {
  width: 100%;
  margin-top: -1px;
  border: 1px solid #949292;
}

.fight-page-card-body-subtitle {
  font-family: 'Proxima Nova Regular', sans-serif;
  font-weight: 700;
  font-size: 17px;
  color: #e4e4e4;
  padding-bottom: 20px;
}

.btn-custom {
  font-family: 'Proxima Nova Regular', sans-serif;
  background: transparent;
  color: #ffffff !important;
  border: 2px solid #23f7dd !important;
  border-radius: 20px !important;
  padding: 7px 15px !important;
  font-size: 20px !important;
  transition: all 0.4s ease-in-out !important;
  letter-spacing: 1px;
}

.btn-custom:hover {
  background: #23f7dd;
  color: black !important;
}

.btn-small {
  font-size: 15px !important;
}

.fight-page-account-card {
  border: 2px solid #a3a1a1;
  padding: 35px;
  background: rgba(22, 22, 22, 0.33);
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  color: #e4e4e4;
}

.fight-page-account-card-body-title {
  font-family: 'Proxima Nova Bold', sans-serif;
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  color: #e4e4e4;
  padding-bottom: 10px;
  border-bottom: 1px solid #656565;
}

.fight-page-account-card-body-subtitle {
  font-family: 'Proxima Nova Regular', sans-serif;
  padding: 15px 0;
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  color: #807d7d;
}

.fight-page-account-card-body-input {
  font-family: 'Proxima Nova Regular', sans-serif;
  background: rgba(22, 22, 22, 0.33) !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: 1px solid #a3a1a1 !important;
  border-radius: 5px 0px 0px 0px !important;
  text-align: center;
}

.fight-page-account-card-body-input-max {
  font-family: 'Proxima Nova Bold', sans-serif;
  background: #a3a1a1 !important;
  border: none !important;
  border-radius: 0px 5px 0px 0px !important;
  color: #1b1b1b !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  cursor: pointer;
}

.text-end {
  text-align: end !important;
}

.btn-end {
  display: block !important;
  float: right;
}

.btn-custom-2 {
  background: #23f7dd !important;
  color: #1b1b1b !important;
  border: 2px solid #23f7dd !important;
  border-radius: 15px !important;
  padding: 7px 15px !important;
  font-size: 15px !important;
  transition: all 0.4s ease-in-out !important;
}

.btn-custom-2:hover {
  background: transparent !important;
  color: #ffff !important;
  border: 2px solid #23f7dd !important;
}

.fight-page-players-position {
  font-family: 'Proxima Nova Regular', sans-serif;
  font-weight: 500;
  font-size: 30px;
  line-height: 40px;
  color: #a3a1a1;
}

.fight-page-players-image {
  border-radius: 20px;
}

.fight-page-logs {
  font-family: 'Proxima Nova Bold', sans-serif;
  font-weight: 700;
  font-size: 40px;
  line-height: 78px;
  color: #ffff;
}

.text-custom-font {
  font-family: 'Proxima Nova Regular', sans-serif;
}

.versus-1,
.versus-2 {
  font-family: 'Batman Bold', sans-serif;
  font-size: 64px;
  color: white;
}

.versus-2 {
  position: relative;
  top: 25px;
  right: 15px;
}

/* Custom Modal */
.modal-content {
  color: #e4e4e4 !important;
  font-family: 'Proxima Nova Regular', sans-serif;
  background-color: #1b1b1b !important;
}

.modal-header {
  border-color: #656565 !important;
}

.modal-footer {
  border-color: #656565 !important;
}

.dominance-item-title {
  font-family: 'Proxima Nova Regular', sans-serif;
  font-weight: 700;
  color: #a3a1a1;
  font-size: 28px;
}

.progress-bar-text {
  font-family: 'Proxima Nova Regular', sans-serif;
  font-weight: 700;
}

@media (max-width: 576px) {
  .page-title {
    font-size: 30px;
    padding: 10px 0 0 0;
  }

  .page-subtitle {
    font-size: 20px;
  }
  .text-small {
    font-size: 25px !important;
  }

  .dominance-item-title {
    font-size: 20px;
  }
}

@media (max-width: 768px) {
  .fight-page-account-card {
    min-height: 580px;
  }
}
